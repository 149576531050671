.create-section-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-modal-popup {
    width: 45%;
    height: 35%;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
  .section-modal-popup h3 {
    margin-top: 30px;
    font-weight: 500;
  }
  .section-modal-buttons {
    margin-top: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  