.annual-sales-plan {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px 0px;
  }
  label{
    margin:0;
  }
  
  header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .filters {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
  }
  .duration-filter{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
  }
  
  .filter-group {
    display: flex;
    align-items: center;
  }
  
  select, input, button {
    margin-left: 5px;
    padding: 5px;
  }
  
  .submit-button {
    background-color: #00a82d;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .download-button {
    background: "#f2f2f2";
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
  
  .table-controls {
    margin-bottom: 10px;
  }
  
  .sales-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .sales-table th, .sales-table td {
    padding: 8px;
    text-align: left;
  }
  
  .sales-table th,select {
    width:100px;
    border:none;
  }
  select
{
  background-color: #f2f2f2;

}
  