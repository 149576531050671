@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:wght@300;500;700&display=swap");
* {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}
#root {
  height: 100vh;
}
.App {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
button,
p,
a, 
span,
label,
th,
td,
/* input, */
select {
  font-size: 13px !important;
}
thead,
tbody,
tfoot {
  vertical-align: middle;
}
th {
  font-weight: bold;
  white-space: nowrap;
  border-top: none !important;
}
td,
th,
tr {
  vertical-align: inherit !important;
  text-align: center;
}
a {
  cursor: pointer;
}
label {
  /* font-size: 17px !important; */
  /* font: size 16px !important; */
  font-weight: bold;
}
input,
.dropdown {
  font-size: 13px !important;
}

.form-control::placeholder {
  font-size: 13px !important;
}
.heading {
  font-size: 20px !important;
  font-weight: bold;
}
.button-text {
  font-size: 20px !important;
}

.form-control {
  width: 100%;
  border: none;
  height: 45px;
  /* outline: none!important; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.form-column1 {
  padding-left: 60px;
  padding-right: 30px;
}
.form-column2 {
  padding-left: 30px;
  padding-right: 60px;
}
.communication-column-left {
  padding-right: 60px;
}
.communication-column-right {
  padding-left: 60px;
}

.form-group {
  margin-bottom: 2rem;
}
/* a:link {
  text-decoration: none;
  color: #000000;
} */
table,
th,
td {
  /* border: 1px solid #ddd; */
  border-collapse: collapse;
}
th {
  border-bottom: none !important;
}
td {
  /* border-bottom: 1px solid #ddd; */
  border-top: none !important;
}
tr {
  border-bottom: 1px solid #ddd;
}

.light-text {
  font-size: 14px;
}
.login-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.login-box {
  width: 30%;
  height: 90vh;
}
.login-form {
  border-radius: 10px;
  background: #fff;
  padding: 40px 20px;
}
.login-form .form-group {
  /* margin: 16px 0px; */
  margin-bottom: 1rem;
}
.login_icon_wrapper {
  display: flex;
  justify-content: center;
}
.login_icon {
  width: 50%;
  border-radius: 3px;
  padding: 5px;
}
.line p {
  font-size: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.line p span {
  background-color: white;
  position: relative;
  top: 10px;
  padding: 0 10px;
}

.form-error {
  font-size: 13px !important;
}
.small-text {
  font-size: 13px !important;
  opacity: 0.5;
}

.input[type="checkbox"] {
  background-color: #00a82d;
}

.button {
  background-color: #00a82d;
  color: #fff;
  border: none;
  border-radius: 5px;
  /* width: 80px; */
  height: 30px;
  padding: 0px 10px;
}

/*sidebar styles*/
/* styles.css (or your preferred stylesheet format) */

.sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  border-radius: 0% 5% 5% 0%;
  transition: 0.5s;
}
.sidebar-option {
  color: #fff;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.sidebar-link {
  padding: 10px;
  color: #fff;
  text-decoration: none !important;
  width: 100%;
  white-space: nowrap;
  font-size: 16px !important;
  display: flex;
  text-wrap: wrap;
}
.sidebar-link-collapsed {
  padding: 10px;
  color: #fff;
  text-decoration: none !important;
  width: 100%;
  white-space: nowrap;
  font-size: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-option.selected {
  /* background-color: #484848; */
  background-color: rgba(0, 168, 45, 0.5);
  border-left: 10px solid #00a82d;
  margin: 0;
  opacity: 1;
}
.sidebar-icon {
  margin-right: 20px;
  height: fit-content;
  /* margin-top: 5px; */
}
.sidebar-bottom {
  position: fixed;
  bottom: 0;
  width: inherit;
}
.sidebar-option:hover {
  background-color: #484848;
  border-left: 10px solid #00a82d;
  margin: 0;
}
.sidebar-link:hover {
  color: #fff;
}

.logout {
  opacity: 1 !important;
}
.top-bar {
  height: 70px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}
.edit_organization {
  margin: 10px 16px 20px;
}
.create-organization {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px 40px 20px;
  border-radius: 10px;
  padding-bottom: 50px;
  position: relative;
  /* height: 100%; */
}
.key-contact {
  /* border: 1px solid #ddd; */
  display: grid;
  border-radius: 10px;
  margin-right: 15px;
  padding: 30px 30px 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px 20px 20px 20px;
  position: relative;
}

.main {
  margin-left: 180px;
  overflow-x: hidden;
  /* overflow: hidden; */
  transition: 0.5s;
  height: 100%;
}

/*search input fields*/
.search-wrapper {
  position: relative;
}
.search-icon {
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  opacity: 50%;
}
.search-input {
  width: 230px;
  box-sizing: border-box;
  box-shadow: 1px 0.5px 2px 0px;
  border-radius: 20px;
  height: 33px;
}
.eye-icon {
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  width: 25px;
}

.edit_icon {
  border-radius: 50%;
}

/*datepicker input fields*/
.datepicker-wrapper {
  position: relative;
}

.datepicker-icon {
  width: 30px;
  height: 30px;
  line-height: 42px;
  text-align: center;
  position: absolute;
  right: 5px;
  z-index: 1;
  opacity: 0.4;
}

.datepicker-input {
  /* box-sizing: border-box;
  height: 30px; */
  position: relative;
}

/*top nav bar*/
.nav-tabs,
.nav-tabs .nav-link {
  margin-bottom: -12px;
  border: none;
  margin-bottom: -1px;
  color: #000 !important;
}
.nav-link {
  padding: 0px 0px 10px 0px;
  margin: 0px 20px;
}
.nav-link:first-child {
  margin-left: 0px;
}
.nav-link:last-child {
  margin-right: 0px;
}
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 6px solid #00a82d;
  color: #000000;
}
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

/*organization-detailsf*/

.details > div {
  padding: 0px 50px;
  margin: 10px 0px;
}
.bg-shadow {
  /* background-color: #f2f2f2; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}
.light_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
/*assigning modules*/
.module {
  width: 30%;
  padding: 40px;
  position: relative;
  border-radius: 10px;
}
.module label {
  font-weight: normal;
}
.module_head {
  font-weight: bold;
  font-size: 13px !important;
}

.package-selection {
  /* padding: 50px; */
  /* position: relative; */
  border-radius: 10px;
}
.package-one {
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 20px;
}
.package-two {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.package-three {
  padding-left: 20px;
  padding-right: 30px;
  border-radius: 10px;
}

/* .package-selection input{

} */

/*date picker*/
.react-datepicker-wrapper {
  display: inherit !important;
}

/*toggle button*/
/* The switch - the box around the slider */
.switch {
  position: absolute;
  display: inline-block;
  width: 45px;
  height: 24px;
  right: 20px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: #ff5353;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:disabled + .slider:before {
  background-color: #a7a5a5;
}
input:checked + .slider:before {
  background-color: #00ff2b;
}
/* input:checked + .slider {
  color:#00a82d;
} */
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(18px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/*table switch*/
.table_switch {
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 18px;
  right: 10px;
  margin-top: 4px;
}
/* Hide default HTML checkbox */
.table_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.table_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.table_slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ff5353;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:disabled + .table_slider:before {
  background-color: #a7a5a5;
}
input:checked + .table_slider:before {
  background-color: #00ff2b;
}
/* input:checked + .slider {
  color:#00a82d;
} */
input:focus + .table_slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .table_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}
/* Rounded sliders */
.table_slider.round {
  border-radius: 34px;
}
.table_slider.round:before {
  border-radius: 50%;
}

/*calendar*/
.react-calendar {
  width: 300px !important;
  border: none !important;
}
.react-calendar button {
  border-radius: 50%;
}
.react-calendar__tile--now {
  background: #fff !important;
}
.react-calendar__tile--active {
  background: #00a82d !important;
}
.react-calendar__tile--hasActive {
  background: #00a82d !important;
}
abbr[title] {
  cursor: default;
  text-decoration: none;
}
.react-calendar__navigation {
  justify-content: center;
}
.react-calendar__navigation__label {
  flex-grow: 0 !important;
  width: 54%;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 50%;
  padding: 0;
}
/* .notification-input {
  border: 1px solid black;
} */
.notification_checkbox {
  font-weight: normal;
}
.custom-checkbox {
  transform: scale(1.5); /* Adjust the scale factor as needed */
  accent-color: #00a82d;
}

/*multi-step*/
.go3842760039:before {
  color: #00a82d !important;
  background-color: #00a82d !important;
  font-size: 14px !important;
}
.go433304200:before {
  background-color: #00a82d !important;
  font-size: 14px !important;
}
.go2150698616 span {
  /* font-size: 16px !important; */
  color: #000000;
}
.go2150698616 {
  line-height: 2.55rem !important;
  border-bottom: 2px solid #b2e5c0 !important;
  padding: 0px 1.7rem !important;
  /* cursor: default !important; */
}
.go169520481 {
  justify-content: center;
  padding-left: 0px !important;
}

.multi-step {
  width: 100%;
}
.multi-step ol li {
  position: relative;
}
.multi-step ol li:first-child::after {
  position: absolute;
  border-bottom: 4px solid #fff;
  bottom: -2.5px;
  width: 51%;
  left: 0;
  z-index: -4;
}
.multi-step ol li:last-child::after {
  position: absolute;
  border-bottom: 4px solid #fff;
  bottom: -2.5px;
  width: 48%;
  right: 0;
  z-index: -4;
}
.cancel-button {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px 20px 20px 20px;
}
.go2335061104:before {
  color: #b2e5c0 !important;
  background: #b2e5c0 !important;
  font-size: 14px !important;
}

li.go2150698616.go433304200 span {
  opacity: 0.5;
}
li.go2150698616.go2335061104 span {
  opacity: 0.5;
}
.go2150698616:before {
  top: 33px;
  line-height: 1.3em !important;
}

/*purhase history*/
.purchase_history_table > td {
  margin-top: 10px;
}
.purchase-left {
  width: 60%;
  padding: 15px;
  margin-right: 2%;
  border-radius: 5px;
  margin-bottom: 5px;
}
.purchase-right {
  width: 35%;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.purchase_history_table tbody::before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}

.select_filters .form-control {
  border-radius: 8px;
  height: 35px;
}
.select_filters .col-4 {
  padding: 0px 18px !important;
}
.select_filters .form-group {
  margin-bottom: 1.5rem;
}

.selected_filters .form-control {
  margin: 0px 18px !important;
}



.modal-content{
  display: flex;
  flex-direction: row;
  border:none;
}
.modal_button {
  border: none;
  border-radius: 5px;
  width: 80px;
  height: 28px;
  margin-top: 16px;
}
.modal_cancel_button {
  color: #00a82d;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.modal_apply_button {
  color: #fff;
  background-color: #00a82d;
}

.counters button {
  border: none;
  background-color: #00a82d;
  color: #fff;
  height: 13px;
  width: 30px;
  padding: 0;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.counters button svg {
  vertical-align: unset;
}
.count {
  font-size: 16px !important;
  width: 25px;
  display: flex;
  justify-content: center;
}

/*customized dropdown styles*/
.multiselect {
  width: 200px;
  position: relative;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  height: 30px;
  /*   font-weight: bold; */
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  /* display: none; */
  border: 1px #dadada solid;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 100%;
}

#checkboxes label {
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px 0px;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

/* input[type="radio"]::before {
  content: "";
  width: 8px;
  height: 8px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #000;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:checked {
  background-color: #fff;
  border: 2px solid #000;
}
input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-grid;
  place-content: center;
  float: right;
  margin-top: 2px;
}

input[type="radio"]::before {
  content: "";
  width: 8px;
  height: 8px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #000;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:checked {
  background-color: #fff;
  border: 2px solid #000;
} */

/*customized multistep form*/
h1 {
  text-align: center;
}
h2 {
  margin: 0;
}
#multi-step-form-container {
  margin-top: 1rem;
}
.text-center {
  text-align: center;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.pl-0 {
  padding-left: 0;
}
.cancel_button {
  width: 80px;
  height: 26px;
  color: #00a82d;
  border: none;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}
.next_button {
  color: #fff;
  height: 26px;
  width: 100px;
  cursor: pointer;
  background-color: #00a82d;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}
.back_button {
  color: #fff;
  height: 26px;
  width: 80px;
  background-color: #00a82d;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}
.submit-btn {
  border: 1px solid #0e9594;
  background-color: #0e9594;
}
.mt-3 {
  margin-top: 2rem;
}
.d-none {
  display: none;
}
.form-step {
  /* border-radius: 20px; */
  padding: 0px 16px;
}
.font-normal {
  font-weight: normal;
}
ul.form-stepper {
  counter-reset: section;
  margin-bottom: 1rem;
}
ul.form-stepper .form-stepper-circle {
  position: relative;
}
ul.form-stepper .form-stepper-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.form-stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* width: 65%; */
  /* margin:0 auto; */
  /* padding:0px 120px; */
}
.form-stepper-horizontal-admin {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* width: 65%; */
  /* margin:0 auto; */
  /* padding:0px 120px; */
}
ul.form-stepper > li:not(:last-of-type) {
  margin-bottom: 0.625rem;
  -webkit-transition: margin-bottom 0.4s;
  -o-transition: margin-bottom 0.4s;
  transition: margin-bottom 0.4s;
}
.form-stepper-horizontal > li:not(:last-of-type) {
  margin-bottom: 0 !important;
}
.form-stepper-horizontal-admin > li:not(:last-of-type) {
  margin-bottom: 0 !important;
}
.form-stepper-horizontal li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.form-stepper-horizontal-admin li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.form-stepper-horizontal li:not(:last-child):after {
  /* position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  content: "";
  top: 32%; */
  position: absolute;
  flex: 1 1;
  height: 2px;
  content: "";
  top: 73%;
  width: 100%;
  /* left: 26.5%; */
  /* left: 50%; */
  /* left: 35%; */
  left: 70px;
  transform: translateY(-50%);
}
.form-stepper-horizontal-admin li:not(:last-child):after {
  position: absolute;
  flex: 1 1;
  height: 2px;
  content: "";
  top: 73%;
  width: 100%;
  /* left: 41%; */
  left: 70px;
  transform: translateY(-50%);
}
.form-stepper-horizontal li:after {
  background-color: #b2e5c0;
}
.form-stepper-horizontal-admin li:after {
  background-color: #b2e5c0;
}
.form-stepper-horizontal li.form-stepper-completed:after {
  background-color: #b2e5c0;
}
.form-stepper-horizontal-admin li.form-stepper-completed:after {
  background-color: #b2e5c0;
}
.form-stepper-horizontal li:last-child {
  flex: unset;
}
.form-stepper-horizontal-admin li:last-child {
  flex: unset;
}
ul.form-stepper li a .form-stepper-circle {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 0;
  line-height: 1.7rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
}
.form-stepper .form-stepper-active .form-stepper-circle {
  background-color: #00a82d !important;
  color: #fff;
  z-index: 1;
}
.form-stepper .form-stepper-active .label {
  /* color: #4361ee !important; */
  font-weight: bold;
}
.form-stepper .form-stepper-active .form-stepper-circle:hover {
  background-color: #4361ee !important;
  color: #fff !important;
}
.form-stepper .form-stepper-unfinished .form-stepper-circle {
  background-color: #b2e5c0;
  z-index: 1;
}
.form-stepper .form-stepper-completed .form-stepper-circle {
  background-color: #00a82d !important;
  color: #fff;
  z-index: 1;
}
/* .form-stepper .form-stepper-completed .label { */
  /* color: #0e9594 !important; */
/* } */
.form-stepper .form-stepper-completed .form-stepper-circle:hover {
  background-color: #0e9594 !important;
  color: #fff !important;
}
.form-stepper .form-stepper-active span.text-muted {
  color: #fff !important;
}
.form-stepper .form-stepper-completed span.text-muted {
  color: #fff !important;
}
.form-stepper .label {
  /* font-size: 1rem; */
  margin-top: 0.5rem;
}
.form-stepper a {
  cursor: default;
  text-decoration: none;
  color: #000;
  width: 140px;
}
.tick {
  margin: 0;
  position: relative;
  top: -4px;
}

input[type="text"]#subdomaintwo {
  -webkit-appearance: none !important;
  color: gray;
  /* width:75px; */
  border: 1px solid gray;
  border-left: 0px;
  margin: 0 0 0 -7px;
  background: white;
  width: 100%;
  border: none;
  outline: none;
  height: 45px;
  border-radius: 3px;
  left: -10px;
  /* outline: none!important; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* box-shadow: 
  2px 2px 8px rgba(0, 0, 0, 0.15), 
  inset -2px 0px 0px #fff; */
}
input[type="text"]#subdomain {
  -webkit-appearance: none !important;
  border: 1px solid gray;
  border-right: 0px;
  outline: none;
  width: 60px;
  background: white;
  border: none;
}

.editorg-label > label {
  display: inline-block;
  min-width: 180px;
}
.editorg-label > label:after {
  content: ": ";
  padding-right: 20px;
}

.keycontact-label > label {
  display: inline-block;
  min-width: 140px;
}
.keycontact-label > label:after {
  content: ": ";
  padding-right: 20px;
}

.deleted_organizations {
  position: relative;
  z-index: -1;
}

select:focus > option:checked,
option:hover {
  background: #00a82d;
  /* selected */
}

.form-group .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 0px solid #cacaca;
  border-radius: 3px 0 0 3px;
}

.bar {
  border: 1px solid white;
}

.organization_setup {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 25px 40px;
  border-radius: 10px;
  padding-bottom: 50px;
  position: relative;
  height: 85%;
}
.org_setup_button {
  background-color: #fff;
  border-radius: 5px;
  width: 150px;
  height: 180px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.usermaster_box{
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 20px;
  border-radius: 10px;
  height: 65vh;
  /* height: auto; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
}
.attribute-heading {
  height: 7%;
  width: 100%;
}
.attributes-container {
  height: 90%;
  overflow-y: auto;
}
.create_section_wrapper {
  width: 30%;
  height: 65vh;
  /* border: 1px solid green; */
  /* padding: 0 20px 20px 20px; */
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.create_section_heading {
  height: 15%;
  display: flex;
  /* gap: 5px; */
  padding: 5px;
}
.create_section_input {
  width: 85%;
  height: 100%;
}
.create_section_input input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(149, 157, 165);
}
.create_section_btn {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
}
.create_section_btn button {
  background-color: inherit;
  border: none;
  width: 90%;
  padding: 0;
}
.section_box_container {
  width: 32%;
  padding: 10px;
  border-radius: 10px;
  /* height: auto; */
  min-height: 75%;
  max-height: 85%;
  overflow-y: auto;
  width: 100%;
  /* box-shadow: 5px 1px 1px rgba(149, 157, 165, 0.2); */
}
.section_box {
  /* height: 250px; */
  min-height: 70%;
  /* height: 70%; */
  position: relative;
  /* max-height: fit-content; */
  /* height: 250px;
  max-height: 250px;  */
  margin: 10px 0;
  overflow-y: auto;
  /* border: 1px solid yellow; */
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.section_box_container :first-child {
  margin-top: 0px;
}
.section_box_container :last-child {
  margin-bottom: 0px;
}
.section_name_heading {
  height: auto;
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.section_name {
  width: 80%;
  /* border: 1px solid green; */
}
.edit-input {
  width: 100%;
  border: none;
  box-shadow: 1px 1px 2px rgba(149, 157, 165);
}
.section-edit-del {
  width: 19%;
  /* border: 1px solid yellow; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.section-edit-btn {
  background-color: inherit;
  border: none;
  padding: 0;
}
.section_attributes_box {
  /* display: flex;
  flex-direction: column;
  gap: 10px; */
  /* border: 1px solid rebeccapurple; */
  min-height: 150px;
}
.attribute {
  /* width: 100%; */
  width: 99%;
  height: 10%;
  margin: 5px auto;
  padding: 10px 40px;
  /* border: 2px solid rgb(63, 228, 63); */
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.8);
}
.search-wrapper-icon{
  position: relative;
  margin: 5px auto;
  width: 230px;
}

.search-icon-attributes {
  background-color: white;
  box-sizing: border-box;
  top: 50%;
  /* left: 7%; */
  transform: translateY(-50%);
  opacity: 50%;
  position: absolute;
  /* left: 15%; */
  left: 15px;
}

/* #style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  height: 10%;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(255, 255, 255, 0);
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: black;
  height: 10%;
} */

#search-library {
  margin: 5px auto;
}

.wrapper {
  position: relative;
}
.logo {
  position: absolute;
  top: 22%;
  left: 4%;
  cursor: pointer;
}
.logo img {
  height: 20px;
  width: 20px;
}
.logo-delete {
  position: absolute;
  top: 22%;
  right: 4%;
  cursor: pointer;
  border: none;
  background-color: white;
}
.logo-delete img {
  height: 20px;
  width: 20px;
}
.custom-attribute-wrapper {
  /* position: absolute; */
  text-align: left;
  transform: translateY(30%);
}
.custom-attribute {
  /* position: relative; */
  /* top: 49vh; */
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  /* border: 2px solid rgb(63, 228, 63); */
  border: 1px solid white;
  box-sizing: border-box;
  box-shadow: 1px 0.5px 2px 0px;
  border-radius: 10px;
  background-color: white;
  /* color: rgba(0, 0, 0, 0.8); */
}
.custom-attribute1 {
  width: 100%;
  height: 100%;
  padding: 7px 10px;
  border: 1px solid white;
  box-sizing: border-box;
  box-shadow: 1px 0.5px 2px 0px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: space-between;
}
.attributes-library {
  min-height: 43vh;
  /* max-height: 90vh; */
  height: 50vh;
  overflow-y: auto;
  border: 1px solid none;
}
.add-custom-attribute img {
  height: 20px;
  width: 20px;
}
.add-icon {
  position: absolute;
  top: 50%;
  left: 90%;
  border: none;
  background-color: rgba(255, 255, 255, 0);
}
.add-custom-attribute {
  position: relative;
  /* position: sticky; */
  /* top: 10px; */
  margin-top: 10px;
}

.custom-attribute-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-modal-popup {
  /* width: 50%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  box-shadow: 1px 0.5px 2px 0px; */

  width: 45%;
  height: 40%;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.input-group {
  margin-top: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
/* .input-group input:first-child {
  margin-bottom: 20px;
} */
.input-group-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid white;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.input-group > * {
  outline: none;
}

h3 {
  text-align: center;
}

#attributes-library-box {
  padding: 20px 20px 10px 20px;
}
.connections {
  margin: 30px 10px 0px 50px;
  position: relative;
  height: auto;
  min-height: 30%;
}
.connections-container {
  position: absolute;
  padding: 10px 0;
}
.connections-container-green {
  height: 40px;
  background-color: #00a82d;
  width: 100px;
  text-align: center;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.connections-container-white {
  position: relative;
  bottom: 15px;
  min-height: 100px;
  background-color: white;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  /* box-shadow: 0 5px 5px rgba(149, 157, 165, 0.2); */
}
.parent-box {
  position: relative;
  left: 100%;
  margin-left: 10px;
}
.attribute-bloomr {
  border: 2px solid rgb(63, 228, 63);
  user-select: none;
}
.attribute-org {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  user-select: none;
}
.user-table {
  height: auto;
  min-height: 83%;
}
.org-structure-container {
  display: flex;
}
.org-structure-child {
  padding: 10px;
  margin: 5px;
  /* height: auto; */
  /* height: 80vh; */
}
.child-1 {
  width: 60%;
  border: 1px solid black;
}
.child-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.nodes {
  display: flex;
  flex-direction: column;
  width: 500px;
}
.nodes-list-tree {
  height: auto;
}
.btn-edit {
  position: absolute;
  transform: translate(550%, -30px);
  background-color: inherit;
  border: none;
  /* position: relative;
  left: 55%;
  top: -25%; */
}
.btn-delete {
  position: absolute;
  transform: translate(650%, -30px);
  background-color: inherit;
  border: none;
}
.add-user {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px 40px 20px;
  border-radius: 10px;
  position: relative;
}
.add-user-modal {
  height: 80%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 25%;
  border-radius: 10px;
  background-color: white;
  overflow-y: auto;
  padding: 10px;
}
.add-user-heading {
  display: flex;
  justify-content: space-between;
}
.close-btn {
  background-color: inherit;
  border: none;
  height: 10px;
}
.add-user-body {
  /* height: 300px; */
  height: auto;
  overflow-y: auto;
}
.add-user-modal h4 {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #00a82d;
  margin-bottom: 10px;
}
.add-user-details {
  height: 90%;
  /* height: 77%; */
  overflow-y: auto;
}
.add-user-span {
  position: relative;
  top: 25vh;
  left: 60%;
  transform: translate(-50%, -50%);
  color: #000;
}
.bulk-upload-modal {
  height: 70%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 45%;
  border-radius: 10px;
  background-color: white;
  overflow-y: auto;
  padding: 25px;
}
.bulk-upload-modal h4 {
  font-weight: bold;
  margin-bottom: 10px;
}
.download-template {
  width: 100%;
  height: 20%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.download-template-text {
  font-weight: bold;
}
.bulk-upload-content-wrapper {
  height: 65%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.upload-files {
  width: 100%;
  height: 60%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #00a82d;
  border-radius: 5px;
}
.upload-files-input {
  border: none;
  background-color: #fff;
  color: #00a82d;
  text-decoration: underline;
  text-decoration-color: #00a82d;
  font-size: 1rem !important;
}
.upload-file-span {
  color: black;
  font-size: 15px !important;
  opacity: 0.5;
}
.required-field::after {
  content: "*";
  color: red;
}
.form-fields {
  height: 375px;
  overflow-y: auto;
}
.org-structure {
  position: relative;
  top: -16px;
  left: 2px;
}
.steps-para {
  position: relative;
  top: 18px;
  left: 2px;
}
.discount-container {
  width: 50%;
}
.form-control-discount {
 width: 100%;
}
.form-group-discount {
  display: flex;
  gap: 1rem;
}
.react-datepicker__navigation {
  top: 10px !important;
  height: 42px !important;
}
.setup-container {
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  height: auto;
  width: 90%;
  margin: 0 auto;
  padding: 5px;
  gap: 1rem;
}
.setup-boxes {
  /* display: flex; */
  height: 300px;
  width: 32%;
  padding: 10px;
}
.customer-setup {
  height: 400px;
}
.second-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.setup-boxes-logo {
  height: 50px;
}
.setup-boxes-logo img {
  height: 20px;
  width: 20px;
  object-fit: cover;
}
.setup-boxes-heading {
  height: 55px;
  width: 83%;
  margin-left: 5px;
}
.setup-boxes-heading > p:first-child {
  font-weight: bold;
}
.setup-boxes-heading > p:last-child {
  font-size: 12px !important;
  opacity: 0.7;
}
.caret-button {
  border: none;
  background-color: #fff;
}
.dropdown-caret {
  height: 40px;
  width: 30px;
}
.dropdown-caret img {
  height: 25px;
  width: 25px;
}
.setup-boxes-heading-container {
  display: flex;
}
.setup-options-container {
  height: 230px;
  /* height: 80%; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}
.setup-options {
  width: 100%;
  height: 50px;
  /* margin: 10px; */
}
.setup-options button {
  border: none;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  text-align: left;
  padding: 5px 15px;
}
.setup-options-container-customer {
  height: 330px;
  /* height: fit-content;
  overflow-y: auto;
  max-height: 250px; */
}
.setup-options-container-user {
  height: fit-content;
  overflow-y: auto;
  max-height: 250px;
}
.setup-boxes-product {
  height: 350px;
}
.setup-options-container-product {
  height: 240px;
}
.ftn-btn {
  min-height: 50px;
}
.list-category {
  font-size: 10px !important;
}
.link-btn {
  display: inline;
  width: fit-content;
  background-color: #fff;
  border: none;
  text-decoration: underline;
  color: #1e90ff;
}
.objective-container {
  height: 100%;
  /* border: 1px solid black; */
  margin: 20px;
  padding: 5px;
}
.objective-body {
  display: flex;
  justify-content: space-between;
  height: 90%;
}
.add-objectives-container {
  height: 80%;
  margin: 20px 10px 10px;
  /* border: 1px solid black; */
  padding: 10px;
}
.objectives-parent {
  display: flex;
  justify-content: space-between;
}
.objectives-headings {
  font-size: 15px !important;
  font-weight: bold;
}
.objectives-body-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* border: 1px solid greenyellow; */
  /* height: 90%; */
  /* height: 100%; */
  height: 430px;
  padding: 20px 0 10px;
  width: 100%;
}
.objective-left-content {
  width: 60%;
}
.objectives-component-left {
  width: 60%;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 5px;
  max-height: 100%;
  overflow-y: auto;
  padding: 5px;
  width: 100%;
  /* padding: 20px 0; */
}
.objectives-component-right {
  width: 40%;
  padding: 20px;
  height: 70%;
  margin: 115px 10px 20px;
  position: relative;
  /* border: 1px solid rebeccapurple; */
}
.heading-text {
  font-size: 15px !important;
  opacity: 0.9;
  margin-bottom: 5px;
}
.add-btn {
  height: 20px;
  border: none;
  background-color: #fff;
  padding-left: 0;
}
.add-new-objective {
  height: 27%;
  /* border: 1px solid blue; */
  margin: 5px 10px 5px;
  width: 95%;
}
.multi-select {
  height: 20%;
}
.add-achievement {
  width: 95%;
  height: fit-content;
}
.add-objective-heading {
  font-size: 12px !important;
  opacity: 0.8;
  width: 70%;
}
.achievement-line {
  display: flex;
  width: 50%;
}
.extra-acheievements {
  padding: 5px;
  max-height: 150px;
  overflow-y: auto;
}
.extra-achievements-wrapper {
  position: relative;
  width: 100%;
}
.delete-achievement {
  position: absolute;
  top: 15px;
  right: 10px;
}
.objective-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
}
.objectives-list-container {
  height: 90px;
  width: 49%;
  padding: 20px 10px 20px;
}
.objective-achievement-wrapper {
  height: 70%;
  display: flex;
}
.objective-heading {
  width: 49%;
  height: 100%;
}
.edit-delete-icon {
  width: 15px;
  height: 15px;
  object-fit: cover;
  vertical-align: baseline;
}
.edit-delete-btn {
  position: relative;
  border: none;
  background-color: inherit;
  z-index: 10;
}
.empty-objectives {
  color: #000;
  font-weight: normal;
  line-height: 2rem;
  font-size: 1rem !important;
}
.empty-objectives-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.empty-error-msg {
  color: red;
  font-size: 12px;
}
.edit-attribute-wrapper {
  width: 40%;
  /* height: 90%; */
  height: fit-content;
  background-color: #fff;
  padding: 10px;
}
.edit-objective {
  width: 95%;
  height: 15%;
  margin: 10px 10px 10px;
}
.tasks-container-left {
  width: 70%;
  /* border: 1px solid blue; */
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  padding: 5px;
}
.tasks-body-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* border: 1px solid greenyellow; */
  /* height: 90%; */
  /* height: 100%; */
  height: 400px;
  padding: 20px 0 10px;
  width: 100%;
}
.task-wrapper {
  height: 70px;
  width: 30%;
}
.task-content {
  width: 100%;
  height: 40px;
  padding: 15px 0 0 10px;
}
.task-edit-delete-btn {
  height: 30px;
}
.tasks-container-right {
  width: 30%;
  /* border: 1px solid blueviolet; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-tasks-container {
  height: 80%;
  margin: 10px 10px 10px;
  /* border: 1px solid black; */
  padding: 10px;
}
.add-tasks-box {
  height: 60%;
  width: 100%;
  /* border: 1px solid red; */
  padding: 20px;
}
.add-new-task {
  height: 27%;
  /* border: 1px solid blue; */
  margin: 10px 0px 25px;
  width: 95%;
}
.visit-restrictions-box {
  width: 45%;
  height: 35%;
  background-color: #fff;
  padding: 10px;
}
.visit-restrictions-input-wrapper {
  padding: 0 5px;
}
.visit-restrictions-input {
  width: 100%;
  padding: 20px;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid white;
  outline: 1px solid gray;
}
.visit-restrictions-btn-wrapper {
  padding: 20px 5px;
}
.travel-plan-box {
  width: 40%;
  height: 400px;
  background-color: #fff;
  padding: 15px;
}
.travel-plan-setup-wrapper {
  position: relative;
  padding: 0 5px;
  width: 100%;
}
.travel-plan-select {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  cursor: pointer;
}
.travel-date-picker {
  margin-top: 5px;
}
.datepicker-select {
  top: 35px;
}
.schemes-datepicker-select {
  top: 9px;
}
/* Parent & Child Connection Edit Starts */
.parent-child-wrapper {
  margin: 15px 10px 10px;
  height: 80%;
}
.view-edit-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 90%;
}
.connections-table {
  width: 60%;
  height: 100%;
  overflow: auto;
  padding-left: 5px;
}
.edit-connection {
  width: 40%;
  height: 100%;
  padding: 10px 20px 20px;
}
.connections-table-head {
  padding: 20px;
  position: sticky;
  top: 0; 
  background-color: #fff; 
}
.no-bottom-border {
  border-bottom: none;
}
.connection-table-child-parent {
  width: 30%;
  padding: 0.75rem 0.5rem 0.5rem !important;
}
.connection-table-edit-action {
  width: 20%;
  padding: 0.75rem 0.5rem 0.5rem !important;
}
.connections-table-row {
  padding: 0.5rem !important;
}
.connections-table-edit-btn {
  padding: 0;
  margin: 0;
  border: none;
  background-color: #fff;
}
.edit-connection-heading {
  font-weight: bold;
}
.edit-connection-modal {
  height: 27%;
  /* border: 1px solid blue; */
  margin: 10px 0 5px;
  width: 95%;
}
/* Parent & Child connection Edit Ends */

/* User Functionality Setup Code Starts */
.user-setup-container {
  height: 85%;
  /* border: 1px solid black; */
  margin: 20px;
  padding: 5px;
}
.add-user-container {
  height: 80%;
  margin: 10px 10px 10px;
  /* border: 1px solid black; */
  padding: 10px;
}
.user-body-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* border: 1px solid greenyellow; */
  /* height: 90%; */
  /* height: 100%; */
  height: 400px;
  padding: 20px 0 10px;
  width: 100%;
}
.user-container-left {
  width: 60%;
  /* border: 1px solid blue; */
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  padding: 5px;
}
.user-container-right {
  width: 40%;
  /* border: 1px solid blueviolet; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.user-wrapper {
  height: 70px;
  width: 30%;
}
.user-content {
  width: 100%;
  height: 40px;
  padding: 15px 0 0 10px;
}
.empty-options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.empty-options {
  color: #000;
  font-weight: normal;
  line-height: 2rem;
  font-size: 1rem !important;
}
.add-option-box {
  height: 60%;
  width: 100%;
  /* border: 1px solid red; */
  padding: 20px;
}
.add-new-option {
  height: 27%;
  /* border: 1px solid blue; */
  margin: 10px 0px 25px;
  width: 95%;
}
.add-option-heading {
  font-size: 12px !important;
  opacity: 0.8;
  width: 70%;
}
.option-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
}
.edit-option-wrapper {
  width: 40%;
  height: 32%;
  background-color: #fff;
  padding: 10px;
}
.edit-option {
  width: 95%;
  height: 15%;
  margin: 10px 10px 10px;
}
.edit-option-heading {
  font-size: 12px !important;
  opacity: 0.8;
  width: 70%;
}
.pending-setup {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* User Functionality Setup Code Ends */

/* Product functionality Setup Code starts */

.product-hierarchy-container {
  display: flex;
  justify-content: center;
  height: 300px;
}
.product-level-input {
  height: 90%;
  width: 40%;
  padding: 20px;
}
.product-input-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 100%;
  height: 28%;
}
.product-input-box-container {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
}
.product-num {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-input-wrapper {
  width: 95%;
  display: flex;
  align-items: center;
}
.product-option-input {
  width: 100%;
  padding: 10px;
  /* margin: 10px 10px; */
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  /* border: 1px solid blue; */
}
.product-option-edit {
  /* border: 1px solid green; */
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
}
.product-option-save {
  justify-content: flex-start;
}
.productmaster_box{
  display: flex;
  flex-direction: column;
  width: 29%;
  padding: 20px;
  border-radius: 10px;
  height: 65vh;
  /* height: auto; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
}
.left_box {
  /* margin-left: 30px;
  margin-right: 0; */
  margin: 0 0 0 30px;
}
.center_box {
  margin: 0 20px;
}
.right_box {
  margin: 0 30px 0 0;
  padding: 20px 20px 10px 20px;
}
.hierarchy-boxes {
  height: 30%;
}
.attributes-boxes {
  height: 70%;
}
/* Product functionality Setup Code Ends */

/* Profile Code starts */
.profile-btn {
  width: 100px;
  background-color: inherit;
  border: none;
}
.profile-container {
  height: 65%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-details {
  height: 54%;
  padding: 10px;
}
.details-heading {
  height: 15%;
  display: flex;
}
.basic-details {
  height: 100%;
  width: 95%;
  font-weight: bold;
  text-align: center;
}
.profile-edit-btn {
  height: 100%; 
  width: 5%;
  text-align: right;
}
.edit-profile-btn {
  background-color: #fff;
  border: none;
  width: 30px;
  padding: 5px;
  border-radius: 50%;
}
.org-name-logo {
  height: 35%;
  display: flex;
  gap: 10px;
  padding-left: 30px;
}
.org-logo img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.org-address {
  width: 50%;
}
.org-name {
  text-align: left !important;
  font-weight: bold;
  line-height: 0.8 !important;
}
.org-specifics {
  height: 55%;
  width: 90%;
  margin: 0 auto;
  padding: 10px 5px 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.org-data {
  width: 19%;
  height: 49%;
}
.org-data-heading {
  font-weight: bold !important;
}
.package {
  height: 20%;
}
.package-specifics {
  height: 80%;
}
.package-data {
  width: 24%;
}
.disabled-tab {
  pointer-events: none;
  opacity: 0.5;
}
.profile-tab-content {
  height: 75%;
}
/* Profile Code ends */

/* Company Details Code starts */
.company-details-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 10px 1.5rem;
  gap: 10px;
  width: 100%;
}
.field-width {
  width: 30%;
  height: 30%;
  margin-bottom: 5px;
}
.company-address {
  height: 100%;
}
/* Company Details Code ends */


/* .step-icons {
  position: relative;
  top: 0;
  left: 1%;
}
.step-icons-skew {
  position: relative;
  top: -10px;
  left: 1%;
} */

.customer-code-table {
  /* width: 50%; */
  height: 80%;
  overflow: auto;
  padding-left: 5px;
  /* margin: 55px 0px 0px 25px; */
}
.cusotmer-code-left {
  width: 50%;
}
.customer-code-right {
  width: 40%;
  padding: 0px 20px;
  height: 70%;
  /* margin: 0px 10px 0px; */
  position: relative;
  /* border: 1px solid rebeccapurple; */
}
.customer-code-box{
  height: 60%;
  width: 100%;
  /* border: 1px solid red; */
  padding: 20px;
  border-radius: 5px;
}
.customer-box-label{
  font-weight: normal;
  margin-bottom: 0
}
input[type='radio'] {
    accent-color: #232323;
}
.prospects-container-right {
  width: 30%;
  /* border: 1px solid blueviolet; */
  display: flex;
  justify-content: center;
}

/* Add Products Code Starts */
.product-font {
  font-size: 1rem !important;
  left: 85%;
}
/* Add Products Code Ends */

.table-cell-data {
  box-sizing: border-box;
  max-width: 50px;
  overflow-x: hidden;
  text-wrap: wrap; 
  text-overflow: ellipsis;
}

/* Add Customer Code start */
.key-contact-heading {
  font-size: 16px !important;
}
.key-contact-index {
  display: block;
  font-size: 15px !important;
  /* padding-top: 5px; */
}
/* Add Customer Code end */

.product-checkbox {
  margin: 5px !important;
  cursor: pointer;
  transform: scale(1.1) !important;
}

/* Set Delivery Code starts  */
.delivery-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 1.5rem 20px;
  height: 80%;
  box-sizing: border-box;
  /* border: 1px solid black; */
}
.delivery-product-table {
  height: 100%;
  /* border: 1px solid blue; */
  padding: 5px;
  width: 48%;
}
.enable-delivery-date {
  height: 100%;
  /* border: 1px solid red; */
  width: 48%;
  padding: 10px;
}
.enable-delivery-date-heading {
  display: flex;
  gap: 10px;
}
.custom-radio {
  transform: scale(1.5); /* Adjust the scale factor as needed */
  accent-color: #00a82d !important;
}
.blur-body {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  width: calc(100% - 197px); /* Adjust width considering the margin-left of .main */
  height: 100%;
  background-color: rgba(190, 255, 255, 0.5);
  z-index: 5;
}
.shrinked-blur {
  width: calc(100% - 71px);
}
.delivery-heading {
  display: inline-block;
  margin-bottom: 0 !important;
}
/* .upper-index {
  position: relative;
  z-index: 6;
}
.lower-index {
  position: relative;
  z-index: 1;
} */
/* Set Delivery Code Ends */

/* add customer */
.bottom-button-container{
  border: none;
  background-color: #00a82d;
  border-radius: 15%;
  float:right
}
.bottom-button-container p{
  color: #fff;
}

.schemes-container-left {
  width: 50%;
  gap: 1rem;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  padding: 5px;
}
.schemes-body-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}
.scheme-wrapper {
  height: 110px;
  width: 90%;
  margin:30px 0px 0px;
  border-radius: 5px;
}
.scheme-product-wrapper {
  height: 350px;
  width: 90%;
  margin:10px 0px;
  border-radius: 5px;
}
.scheme-content {
  width: 87%;
  height: auto;
}
.scheme-edit-delete-btn {
  height: 30px;
}
.schemes-container-right {
  width: 40%;
  justify-content: center;
  overflow-y: auto;
}
.add-schemes-container {
  height: 85%;
  margin: 10px 10px 10px;
  padding: 10px;
}
.add-schemes-box {
  height: 60%;
  width: 100%;
}
.add-new-scheme {
  height: 27%;
  margin: 10px 0px 25px;
  width: 95%;
}
textarea {
  resize: none;
}
.assign_button {
  color: #fff;
  height: 26px;
  background-color: #00a82d;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}
.upload_target_button{
  color: #fff;
  height: 26px;
  background-color: #ffe536;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}

.order-email-modal {
  height: 80%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 70%;
  border-radius: 10px;
  background-color: white;
  overflow-y: auto;
  padding: 10px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.order_email_heading{
  font-size: 14px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.time-slot-section {
}
.slot-number, .slot-time{
  color:#000;
  font-size: 14px;
}

.add-time-slot {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.time-input-wrapper {
  position: relative;
}

.time-input-wrapper input {
  width: 90%;
  padding: 8px 8px 8px 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.clock-icon {
  position: absolute;
  left: 130px;
  top: 50%;
  transform: translateY(-50%);
  width:20px;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.time-slots-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.time-slot-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.slot-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button.enabled {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.upload_target_modal {
  height: 50%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 30%;
  border-radius: 10px;
  background-color: white;
  overflow-y: auto;
  padding: 20px;
}