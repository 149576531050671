.container-quick-guide {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 80%;
    background-color: #00a82d;
    opacity: 0.9;
    border-top-right-radius: 25%;
    border-bottom-left-radius: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(118, 113, 113, 0.5); /* semi-transparent black background */
}

.quick-tip {
    /* padding: 50px 200px 60px 60px; */
    height: 80%;
    padding-top: 5%;
    padding-left: 4%;
    /* align-self: flex-start; */
}

.text {
    display: block;
    color: white;
    font-weight: bolder;
    font-size: 2rem !important;
    line-height: 4rem;
}

.ok-btn {
    align-self: flex-end;
    padding-left: 100px;
    padding-bottom: 100px;
}

.ok-btn1 {
    align-self: flex-end;
    padding-bottom: 10%;
    width: 20%;
    /* padding-left: 80%; */
    /* padding-bottom: 100px; */
}

.ok-btn1 button {
    color: #046713;
    background-color: white;
    font-weight: bolder;
    width: 70px;
    border: none;
    border-radius: 5px;
    height: 30px;
}

.para-text, span {
    display: block;
    font-size: 1.5rem !important;
    font-weight: bold;
    line-height: 3rem;
    color: white;
}

.quick-tip1 {
    /* box-sizing: border-box;
    padding: 35px 50px 20px 30px; */
    align-self: flex-start;
    width: 80%;
    height: 78%;
    padding-top: 2%;
    padding-left: 4%;
}

.para-body {
    display: block;
    color: white;
    font-size: 1rem !important;
    line-height: 1.5rem;
    font-weight: bold;
    margin: 25px 10px 25px 10px;
}